import { getOr } from 'lodash/fp';
import getHeader from '@ahmdigital/logic/lib/content/offers/unqualified/get-header';
import getOfferEndSummary from '@ahmdigital/logic/lib/content/offers/unqualified/get-offer-end-summary';
import getPromoCode from '@ahmdigital/logic/lib/content/offers/unqualified/get-promo-code';
import getPromoCodeDescription from '@ahmdigital/logic/lib/content/offers/unqualified/get-promo-code-description';
import getSubtext from '@ahmdigital/logic/lib/content/offers/unqualified/get-subtext';

import { Offer } from '../../../types';
import getIsReferralCodeType from '../../../logic/get-is-referral-code-type';
import getOfferImage from '../../../utils/get-offer-image';
import type { ImageType } from '../../../../../types/image';
import type { PortableTextType } from '../../../../../types/sanityInternal';

export type Value = {
  value: string;
};

export type Subtext = {
  value: Value[];
};

type Marks = {
  href: string;
  type: string;
  inNewTab?: boolean;
}[];

export type Ends = {
  value: Value & Marks[];
};

type ReturnValues = {
  image: ImageType | null;
  header: PortableTextType | null;
  subtext: Subtext | null;
  sublist: Subtext | null;
  promoCode?: string | null;
  promoCodeDescription: string | null;
  subtextPrefix: string | null;
  ends: Ends | null;
};

const getOrNull = getOr(null);

const getContentForOffer = (offer?: Offer, referralCode?: string | null): ReturnValues => {
  const image = getOfferImage(offer);
  const header = getHeader(offer) as any;
  const subtext = getSubtext(offer, false);
  const sublist = getSubtext(offer, true);
  const isReferralCodeType = getIsReferralCodeType(offer);
  const promoCode = isReferralCodeType ? referralCode : getOrNull('value', getPromoCode(offer));
  const promoCodeDescription = getOrNull('value', getPromoCodeDescription(offer));
  const subtextPrefix = getOr(null, 'offerTypes[0].summary', offer);
  const ends = getOfferEndSummary(offer);

  return {
    ends,
    header,
    image,
    promoCode,
    promoCodeDescription,
    sublist,
    subtext,
    subtextPrefix,
  };
};

export default getContentForOffer;
