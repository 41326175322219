import { noop } from 'lodash/fp';

const serverStub = {
  __productReviewCallbackQueue: {
    push: noop,
  },
  __productReviewSettings: {},
  activeElement: null,
  addEventListener: noop,
  body: {
    appendChild: noop,
    removeChild: noop,
    style: {
      overflow: '',
    },
  },
  createElement: noop,
  getElementById: noop,
  gtag: noop,
  location: {},
  querySelector: noop,
  removeEventListener: noop,
  scrollTo: noop,
  sessionStorage: {
    getItem: () => null,
    removeItem: () => null,
    setItem: () => null,
  },
  supports: noop,
};

function getDocument(): Document | typeof serverStub {
  if (typeof window !== 'undefined') {
    return document;
  }
  return serverStub;
}

type ProductReviewSettings = {
  brandId: string;
};

export type ProductReview = {
  use: (widgetType: string, settings: unknown) => void;
  destroy: (widgetType: string, settings: unknown) => void;
};

export type WindowType = Window & {
  __productReviewSettings?: ProductReviewSettings;
  __productReviewCallbackQueue: {
    push: (callback: (productReview: ProductReview) => void) => void;
  };
};

function getWindow(): WindowType | typeof serverStub {
  if (typeof window !== 'undefined') {
    return window as unknown as WindowType;
  }
  return serverStub;
}

function getCss() {
  if (typeof window !== 'undefined') {
    return CSS;
  }
  return serverStub;
}

export default {
  getCss,
  getDocument,
  getWindow,
};
