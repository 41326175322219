import { AGR_TABLE, INCOME_TYPE, REBATE_AGE_BRACKET } from '@ahmdigital/constants';
import { flatMap, flow, maxBy, minBy, toNumber, values, without } from 'lodash/fp';

import config from '../../config';

export const INCOME_TYPE_OPTIONS = [
  { text: 'Single income', value: INCOME_TYPE.SINGLE },
  { text: 'Couple or family — Incl. single parent', value: INCOME_TYPE.COUPLE },
];

export const INCOME_TYPE_LABEL = {
  [INCOME_TYPE.SINGLE]: 'Single',
  [INCOME_TYPE.COUPLE]: 'Family',
};

export const REBATE_AGE_BRACKET_OPTIONS = [
  { text: 'Under 65 years old', value: REBATE_AGE_BRACKET.BRACKET_0 },
  { text: '65 to 69 years old', value: REBATE_AGE_BRACKET.BRACKET_1 },
  { text: '70 years or over', value: REBATE_AGE_BRACKET.BRACKET_2 },
];

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly '2018-04-01': { readonly "0": { readonly "0": "25.415"; readonly "1": "16.943"; readonly "2": "8.471"; readonly "3": "0"; }; readonly "1": { readonly "0": "29.651"; readonly "1": "21.180"; readonly "2": "12.707"; readonly "3": "0"; }; readonly "2": { ...; }; }; ... 8 more ...; readonly '2024-04-01': { ......'.
export const REBATE_PERCENTAGES = AGR_TABLE[config.get('rebate:effectiveDate')];
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly '2018-04-01': { readonly "0": { readonly "0": "25.415"; readonly "1": "16.943"; readonly "2": "8.471"; readonly "3": "0"; }; readonly "1": { readonly "0": "29.651"; readonly "1": "21.180"; readonly "2": "12.707"; readonly "3": "0"; }; readonly "2": { ...; }; }; ... 8 more ...; readonly '2024-04-01': { ......'.
export const REBATE_PERCENTAGES_PREMIUM_CHANGE = AGR_TABLE[config.get('features:premiumChange:futureEffectiveDate')];

// @ts-expect-error - Automatic, Please fix when editing this file
export const getExtreme = ({ method, percentagesObject }) =>
  flow(flatMap(values), without(['0']), method(toNumber))(percentagesObject);

export const MIN_REBATE_PERCENTAGE = getExtreme({
  method: minBy,
  percentagesObject: REBATE_PERCENTAGES,
});

export const MAX_REBATE_PERCENTAGE = getExtreme({
  method: maxBy,
  percentagesObject: REBATE_PERCENTAGES,
});

export const MIN_REBATE_PERCENTAGE_PREMIUM_CHANGE = getExtreme({
  method: minBy,
  percentagesObject: REBATE_PERCENTAGES_PREMIUM_CHANGE,
});

export const MAX_REBATE_PERCENTAGE_PREMIUM_CHANGE = getExtreme({
  method: maxBy,
  percentagesObject: REBATE_PERCENTAGES_PREMIUM_CHANGE,
});
